body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popup {
  filter: drop-shadow(0px 6px 3px #555555);
  position: absolute;
  background-color: rgb(235, 101, 101);
  margin-top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 6px;
  animation-name: myAnimation;
  animation-duration: 4000ms;
  animation-fill-mode: forwards;
  border-radius: 4px;
}

.scrollable {
  cursor: grab;

}

@keyframes myAnimation{
  0%{
    opacity: 0;
  }
  10%{
    opacity: 1;
  }
  70%{
    opacity: 1;
  }
  100%{
    display: none;
    opacity: 0;
  }
}


a {
  text-decoration: none;
}
 /* unvisited link */
 a:link {
  color: rgb(105, 243, 174);
    
}

/* visited link */
a:visited {
  color: rgb(105, 243, 174);
}

/* mouse over link */
a:hover {
  filter: drop-shadow(0px 1px 1px #b4b4b4);
}

.noCoinsSelected {
  color: #b3b3b3;
  font-size: 16pt;
  position: absolute;
  margin-top: 150pt;
  margin-bottom: 50pt;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 6px;
}

.center{
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  margin: auto;
 }

/* loading spinner */
.spinner{
  width: 80px;
  height: 80px;
  
  border: 2px solid #3a3a3a;
  border-top:3px solid rgb(105, 243, 174);
  border-radius: 100%;

  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, -50%);
  
  animation: spin 1s infinite linear;
  }
  
@keyframes spin {
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(360deg);
  }
}


.center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.lds-ellipsis {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, -50%);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ec7147;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 58px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 108px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(50px, 0);
  }
} 

.overlay{
  font-size: 50pt;
  font-weight: 700;
  position: absolute;
  margin-top: 150pt;
  margin-bottom: 50pt;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 6px;
  background-color: white;
  } 

.checkbox{
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    margin:10px;
    padding:5px;
  }